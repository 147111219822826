// src/hooks/useThreads.ts
import { useState } from 'react';
import axios from 'axios';
import apiConfig from "../apiConfig";

export interface ChatThread {
    id: number;
    parentType: string;
    parentId: string;
    title: string;
}

export interface ThreadMessage {
    id: number;
    message: string;
    timestamp: string;
    performedBy: string;
}

export function useThreads(parentType: string, parentId: string) {
    const [thread, setThread] = useState<ChatThread | null>(null);
    const [messages, setMessages] = useState<ThreadMessage[]>([]);

    const loadMessages = async () => {
        try {
            const res = await axios.get(`${apiConfig.REACT_APEX_BASE_API}/thread/${parentType}/${parentId}`);
            setThread(res.data.thread);
            setMessages(res.data.messages || []);
        } catch (err) {
            console.error('Error loading thread:', err);
        }
    };

    const addReply = async (text: string) => {
        try {
            const res = await axios.post(`${apiConfig.REACT_APEX_BASE_API}/thread/${thread?.id}/reply`, { message: text });
            setMessages((prev) => [...prev, res.data]);
        } catch (err) {
            console.error('Error adding reply:', err);
        }
    };
    return { thread, messages, loadMessages, addReply };
}
