import { useState } from 'react';
import axiosApexInstance from '../auth/axiosApexInstance'; // ✅ Use the custom instance
import { useUser } from '../context/UserContext';

export interface UserTask {
    id?: number;
    title: string;
    status: string;
    assignedTo: string;
    dueDate?: string;
    description?: string;
    createdBy?: string;
    createdAt?: string;
}

export interface UserTaskActivity {
    id?: number;
    taskId: number;
    activityText: string;
    createdBy: string;
    createdAt?: string;
}

export function useTasks() {
    const { accessToken } = useUser(); // still needed to ensure context is loaded
    const [tasks, setTasks] = useState<UserTask[]>([]);

    const loadTasks = async () => {
        const res = await axiosApexInstance.get('/task');
        setTasks(res.data);
    };

    const createTask = async (task: Partial<UserTask>) => {
        const res = await axiosApexInstance.post('/task', task);
        await loadTasks();
        return res.data;
    };

    const addActivity = async (taskId: number, activity: Partial<UserTaskActivity>) => {
        return axiosApexInstance.post(`/task/${taskId}/activity`, activity);
    };

    const getActivityLog = async (taskId: number) => {
        const res = await axiosApexInstance.get(`/task/${taskId}/activity`);
        return res.data;
    };

    return { tasks, loadTasks, createTask, addActivity, getActivityLog };
}
