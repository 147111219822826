import React from 'react';
import {
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useUser } from '../context/UserContext';
import { useRingCentralData } from '../context/RingCentralDataContext';
import axiosApexInstance from '../auth/axiosApexInstance';

const UserAvatarMenu = () => {
    const { user, signOutUser, accessToken, unauthorizedLog } = useUser();
    const { refreshData, clearData } = useRingCentralData();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleLogout = async () => {
        clearData();
        await signOutUser();
    };

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const triggerPost = async (url: string, label: string) => {
        try {
            const response = await axiosApexInstance.post(url);
            const result = await response.data;
            console.log(`${label} Result:`, result);
            if (label.includes('RC')) refreshData();
        } catch (error) {
            console.error(`Failed to ${label}`, error);
        } finally {
            handleClose();
        }
    };

    const handleShow403Errors = () => {
        const forbiddenUrls = unauthorizedLog.filter(entry =>
            entry.url // && entry.url.includes('/chat') || entry.url.includes('/task') // example: filter by route if needed
        );
        if (forbiddenUrls.length === 0) {
            alert('No 403 errors found.');
        } else {
            alert(
                '403 Errors:\n\n' +
                forbiddenUrls
                    .map((entry) => `${entry.time} — ${entry.url}`)
                    .join('\n')
            );
        }
        handleClose();
    };

    return (
        <>
            <Tooltip title="User Menu">
                <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                    <Avatar alt={user?.displayName || 'User'} src="/path-to-user-photo.jpg" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={handleClose}>
                    <ListItemIcon><SettingsIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary="Settings" />
                </MenuItem>

                <MenuItem onClick={() => triggerPost('/ringcentral/process-hook-rows', 'Trigger RC Processing')}>
                    <ListItemIcon><PlayCircleOutlineIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary="Trigger RC Processing" />
                </MenuItem>

                <MenuItem onClick={() => triggerPost('/ringcentral/save-call-log-since?days=1', 'Trigger Hook Call Log')}>
                    <ListItemIcon><PlayCircleOutlineIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary="Trigger Hook Call Log" />
                </MenuItem>

                <MenuItem onClick={() => triggerPost('/ringcentral/resubscribe', 'Trigger Resubscribe')}>
                    <ListItemIcon><PlayCircleOutlineIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary="Trigger Resubscribe" />
                </MenuItem>

                <MenuItem onClick={() => triggerPost('', 'Trigger RCData Refresh')}>
                    <ListItemIcon><PlayCircleOutlineIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary="Trigger RCData Refresh" />
                </MenuItem>

                <MenuItem onClick={handleShow403Errors}>
                    <ListItemIcon><ReportProblemIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary="View 403 Errors" />
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                    <ListItemIcon><LogoutIcon fontSize="small" /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserAvatarMenu;
