import React, { useState, useMemo } from 'react';
import {
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemButton,
    Divider,
    Grid,
    Button,
} from '@mui/material';
import { useRingCentralData } from '../context/RingCentralDataContext';
import ApexPdfjsViewer from './ApexPdfjsViewer';
import apiConfig from "../apiConfig";
import { handleAgDocument } from "./handleDocuments";
import { useUser } from "../context/UserContext";
import ContextPanel from '../components/ContextPanel';  // Import the ContextPanel component

const FaxListPage = () => {
    const { faxes } = useRingCentralData();
    const { accessToken, apexUser } = useUser();
    const [selectedFax, setSelectedFax] = useState<any | null>(null);
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);

    const apiURL = apiConfig.REACT_APEX_BASE_API;
    const sortedFaxes = useMemo(() => {
        return [...faxes].sort((a, b) => new Date(b.rowDate).getTime() - new Date(a.rowDate).getTime());
    }, [faxes]);

    const handleFaxClick = (fax: any) => {
        setSelectedFax(fax);
        const relativePath = fax.filepath?.replace(/^.*?apexdocs\//, '');
        const fullUrl = `apexdocs~${normalizePath(relativePath)}`;

        handleAgDocument({
            filename: relativePath,
            dirpath: '',
            startpage: 1
        }, apiURL, accessToken, setPdfUrl, setCurrentPageNum);
    };

    const normalizePath = (relativePath: string) => {
        return relativePath && relativePath.replace(/[\\/]/g, '~');
    };

    const getSenderDisplay = (fax: any) => {
        try {
            const json = JSON.parse(fax.jsonText);
            return json?.from?.name || fax.fromNumber;
        } catch {
            return fax.fromNumber;
        }
    };

    return (
        <Box display="flex" height="calc(100vh - 64px)">
            {/* Left Side: Fax List */}
            <Box width="50%" height={'50%'}  pr={2}>
                <Box height="300px" overflow="auto" pr={2}>
                    <List>
                        {sortedFaxes.map((fax) => (
                            <React.Fragment key={fax.id}>
                                <ListItem disablePadding>
                                    <ListItemButton component="div" onClick={() => handleFaxClick(fax)}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">From: {getSenderDisplay(fax)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2">
                                                    Date: {new Date(fax.rowDate).toLocaleString()}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Box>

                    {/* Context Panel for Fax Chat */}
                    <Box mt={4}>
                        {/* ContextPanel for the selected fax context */}
                        <ContextPanel
                            contextType="fax"
                            contextId={selectedFax?.id || 0}  // Pass selected fax id or 0 if none
                            userName={apexUser ? `${apexUser.userFirstName} ${apexUser.userLastName}` : ''}
                            userId={apexUser?.userId || 0}
                        />
                    </Box>
            </Box>

            {/* Right Side: PDF Viewer for the selected fax */}
            <Box width="50%" pl={2} borderLeft="1px solid #ccc" overflow="auto">
                {selectedFax ? (
                    <Paper elevation={3} sx={{ height: '100%', p: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Fax from {getSenderDisplay(selectedFax)}
                        </Typography>
                        {/* PDF viewer for the fax */}
                        <ApexPdfjsViewer pdfUrl={pdfUrl} startingPageNum={1} setCurrentPageNum={() => {}} />
                    </Paper>
                ) : (
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                        Select a fax to view its content and interact with the context.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default FaxListPage;
