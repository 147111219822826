// VoiceMailListPage.tsx - Displays voicemails in a scrollable list with preview toggle and full transcript view

import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Checkbox,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemButton,
    Divider,
} from '@mui/material';
import { useRingCentralData } from '../context/RingCentralDataContext';
import apiConfig from "../apiConfig";
import {useUser} from "../context/UserContext";

const CallLogListPage = () => {
    const { callog } = useRingCentralData();
    const { accessToken } = useUser();
    const [selectedVoicemail, setSelectedVoicemail] = useState<any | null>(null);
    const [enablePreview, setEnablePreview] = useState(true);
    const [sortedCalls, setSortedCalls] = useState<any[]>([]);

    useEffect(() => {
        if (callog && Array.isArray(callog)) {
            const sorted = [...callog].sort((a, b) => new Date(b.rowDate).getTime() - new Date(a.rowDate).getTime());
            setSortedCalls(sorted);
        }
    }, [callog]);

    const getFromDisplay = (vm: any) => {
        try {
            const json = JSON.parse(vm.jsonText);
            return json?.from?.name ? `${json.from.name} (${vm.fromNumber}) (${vm.callAction}) (${vm.callResult}) ` : vm.fromNumber;
        } catch {
            return vm.fromNumber;
        }
    };

    const getAudioUrl = (filepath: string, token: string) => {
        const apiURL = `${apiConfig.REACT_APEX_BASE_API}/apexai`;
        const relativePath = filepath?.replace(/^.*?apexdocs[\\/]/, '');
        const safePath = relativePath.replaceAll("\\", "~").replaceAll("/", "~");
        return `${apiURL}/getmediafile/${safePath}?token=${encodeURIComponent(token)}`;
    };


    return (
        <Box display="flex" height="calc(100vh - 64px)">
            <Box width="50%" overflow="auto" pr={2}>
                <Box mb={2}>
                    <FormControlLabel
                        control={<Checkbox checked={enablePreview} onChange={(e) => setEnablePreview(e.target.checked)} />}
                        label="Enable Preview"
                    />
                </Box>
                <List>
                    {sortedCalls.map(vm => (
                        <React.Fragment key={vm.id}>
                            <ListItem disablePadding>
                                <ListItemButton component="div" onClick={() => setSelectedVoicemail(vm)} alignItems="flex-start">
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}><Typography variant="subtitle2">From: {getFromDisplay(vm)}</Typography></Grid>
                                        <Grid item xs={7}><Typography variant="subtitle2">Date: {new Date(vm.rowDate).toLocaleString()}</Typography></Grid>
                                        {enablePreview && vm.trantext && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="textSecondary">{vm.trantext.substring(0, 100)}...</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </Box>

            <Box width="50%" pl={2} borderLeft="1px solid #ccc">
                {selectedVoicemail ? (
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="h6">Voicemail Transcript</Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>{selectedVoicemail.trantext}</Typography>
                        {selectedVoicemail.filepath && (
                            <Box mt={2}>
                                <Typography variant="subtitle2">Audio Playback:</Typography>
                                <audio controls src={getAudioUrl(selectedVoicemail.filepath!, accessToken!)} style={{ width: '100%' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>
                        )}
                    </Paper>
                ) : (
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>Select a voicemail to view transcript.</Typography>
                )}
            </Box>
        </Box>
    );
};

export default CallLogListPage;
