import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useTasks, UserTaskActivity } from '../hooks/useTasks';

const TaskActivityPanel = ({ taskId }: { taskId: number }) => {
    const { getActivityLog } = useTasks();
    const [activities, setActivities] = useState<UserTaskActivity[]>([]);

    useEffect(() => {
        const load = async () => {
            const result = await getActivityLog(taskId);
            setActivities(result);
        };
        load();
    }, [taskId]);

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6">Task Activity</Typography>
            <Divider sx={{ my: 1 }} />

            {activities.map((activity) => (
                <Box key={activity.id} sx={{ mb: 2 }}>
                    <Typography variant="body2">
                        <strong>{activity.createdBy}</strong> — {new Date(activity.createdAt ?? '').toLocaleString()}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {activity.activityText}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default TaskActivityPanel;