// themes/springTheme.ts
import { ThemeOptions } from '@mui/material';

export const springTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#81c784',
        },
        secondary: {
            main: '#aed581',
        },
        background: {
            default: '#f1f8e9',
        },
    },
};
