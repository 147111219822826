// VoiceMailListPage.tsx - Displays voicemails with ContextPanel and transcript/audio preview
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Checkbox,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemButton,
    Divider,
    Button,
    Stack,
} from '@mui/material';
import { useRingCentralData } from '../context/RingCentralDataContext';
import apiConfig from '../apiConfig';
import { useUser } from '../context/UserContext';
import ThreadPanel from '../components/chatthreads/ThreadPanel';
import TaskPanel from './TaskPanel';
import ContextPanel from '../components/ContextPanel';

const VoiceMailListPage = () => {
    const { voicemails } = useRingCentralData();
    const { accessToken, apexUser } = useUser();
    const [enablePreview, setEnablePreview] = useState(true);
    const [sortedVoicemails, setSortedVoicemails] = useState<any[]>([]);
    const [openThread, setOpenThread] = useState(false);
    const [showTaskPanel, setShowTaskPanel] = useState(false);
    const [selectedVoicemail, setSelectedVoicemail] = useState<any | null>(null);

    useEffect(() => {
        if (voicemails && Array.isArray(voicemails)) {
            const sorted = [...voicemails].sort((a, b) => new Date(b.rowDate).getTime() - new Date(a.rowDate).getTime());
            setSortedVoicemails(sorted);
        }
    }, [voicemails]);

    const getFromDisplay = (vm: any) => {
        try {
            const json = JSON.parse(vm.jsonText);
            return json?.from?.name ? `${json.from.name} (${vm.fromNumber})` : vm.fromNumber;
        } catch {
            return vm.fromNumber;
        }
    };

    const getAudioUrl = (filepath: string, token: string) => {
        const apiURL = `${apiConfig.REACT_APEX_BASE_API}/apexai`;
        const relativePath = filepath?.replace(/^.*?apexdocs[\\/]/, '');
        const safePath = relativePath.replaceAll("\\", "~").replaceAll("/", "~");
        return `${apiURL}/getmediafile/${safePath}?token=${encodeURIComponent(token)}`;
    };

    return (
        <Grid container height="calc(100vh - 64px)">
            <Grid item xs={12} md={5} borderRight="1px solid #ccc">
                <Box height="300px" overflow="auto" pr={2}>
                    <Box mb={2}>
                        <FormControlLabel
                            control={<Checkbox checked={enablePreview} onChange={(e) => setEnablePreview(e.target.checked)} />}
                            label="Enable Preview"
                        />
                    </Box>
                    <List>
                        {sortedVoicemails.map(vm => (
                            <React.Fragment key={vm.id}>
                                <ListItem disablePadding>
                                    <ListItemButton component="div" onClick={() => setSelectedVoicemail(vm)} alignItems="flex-start">
                                        <Grid container spacing={1}>
                                            <Grid item xs={5}><Typography variant="subtitle2">From: {getFromDisplay(vm)}</Typography></Grid>
                                            <Grid item xs={7}><Typography variant="subtitle2">Date: {new Date(vm.rowDate).toLocaleString()}</Typography></Grid>
                                            {enablePreview && vm.trantext && (
                                                <Grid item xs={12}>
                                                    <Typography variant="body2" color="textSecondary">{vm.trantext.substring(0, 100)}...</Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Box>

                <Box height="70%" overflow="auto" borderTop="1px solid #ccc" p={1}>
                    <Typography variant="subtitle1" gutterBottom>Tasks & Chat</Typography>
                    <ContextPanel
                        contextType="voicemail"
                        contextId={selectedVoicemail?.id || 0}
                        userName={apexUser ? `${apexUser.userFirstName} ${apexUser.userLastName}` : ''}
                        userId={apexUser?.userId || 0}
                    />
                </Box>
            </Grid>

            <Grid item xs={12} md={7} p={2}>
                {selectedVoicemail ? (
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="h6">Voicemail Transcript</Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>{selectedVoicemail.trantext}</Typography>

                        {selectedVoicemail.filepath && (
                            <Box mt={2}>
                                <Typography variant="subtitle2">Audio Playback:</Typography>
                                <audio controls src={getAudioUrl(selectedVoicemail.filepath!, accessToken!)} style={{ width: '100%' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>
                        )}

                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                            <Button variant="outlined" onClick={() => setOpenThread(true)}>Open Chat</Button>
                            <Button variant="outlined" onClick={() => setShowTaskPanel(true)}>Add Task</Button>
                        </Stack>
                    </Paper>
                ) : (
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                        Select a voicemail to view transcript.
                    </Typography>
                )}
            </Grid>

            {openThread && selectedVoicemail && (
                <Box sx={{ position: 'fixed', bottom: 70, right: 20, zIndex: 3000 }}>
                    <ThreadPanel parentType="voicemail" parentId={selectedVoicemail.id.toString()} onClose={() => setOpenThread(false)} />
                </Box>
            )}

            {showTaskPanel && selectedVoicemail && (
                <TaskPanel
                    initialComment={selectedVoicemail.trantext}
                    onClose={() => setShowTaskPanel(false)}
                    onCreated={(taskId) => {
                        console.log("✅ Task created for voicemail:", selectedVoicemail.id);
                        setShowTaskPanel(false);
                    }}
                />
            )}
        </Grid>
    );
};

export default VoiceMailListPage;
