// src/components/EntryPage.tsx
import React from 'react';
import { Box, Typography, Stack, Button, CircularProgress } from '@mui/material';
import LoginAD from './LoginAD';

interface EntryPageProps {
    onGoogleLogin: () => void;
    onADLogin: (username: string, password: string) => Promise<void>;
    adLoading: boolean;
    adLoginOpen: boolean;
    setAdLoginOpen: (open: boolean) => void;
}

const EntryPage: React.FC<EntryPageProps> = ({
                                                 onGoogleLogin,
                                                 onADLogin,
                                                 adLoading,
                                                 adLoginOpen,
                                                 setAdLoginOpen
                                             }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                p: 4
            }}
        >
            <Typography variant="h4" gutterBottom>Welcome to Apex Clinic Workflow Manager</Typography>
            <Typography variant="body1" gutterBottom>Please sign in to continue.</Typography>
            <Stack direction="row" spacing={2} mt={2}>
                <Button variant="contained" onClick={onGoogleLogin} disabled={adLoading}>
                    {adLoading ? <CircularProgress size={20} /> : 'Google Login'}
                </Button>
                <Button variant="contained" color="secondary" onClick={() => setAdLoginOpen(true)} disabled={adLoading}>
                    {adLoading ? <CircularProgress size={20} /> : 'Apex AD Login'}
                </Button>
            </Stack>
            <LoginAD
                open={adLoginOpen}
                onClose={() => setAdLoginOpen(false)}
                onLogin={onADLogin}
                loading={adLoading}
            />
        </Box>
    );
};

export default EntryPage;
