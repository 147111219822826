import {useState, useEffect } from 'react';

const appPort = 8044;
const apiConfig = {

    API_URL : "https://localhost:8044",
    REACT_APP_AUTHENTICATION : "https://app.apexheartcare.com:8044/action",
    REACT_APP_BASE_API: "https://app.apexheartcare.com:8044/action",
    REACT_APEX_BASE_API: "https://app.apexheartcare.com:8044",
    PDFJS_KEY : "8JJ9A6eCpr4Txx6tx880",
    REACT_APEX_BASE_API_LOCAL: "https://localhost:8090",

    //9LQhM9GRvXPHexJZYq6W  (192.168.21.192)
    // apexheartcare.com  '8JJ9A6eCpr4Txx6tx880',
    // 192.168.21.60 '6lZyB45Mi2LYcJcy618X",
}


// const appPort = 8090;
// const apiConfig = {
//     API_URL : `https://192.168.21.43:${appPort}`,
//     REACT_APP_AUTHENTICATION : `https://localhost:${appPort}/action`,
//     REACT_APP_BASE_API: `https://192.168.21.43:${appPort}/action`,
//     REACT_APEX_BASE_API: `https://192.168.21.43:${appPort}`,
//     PDFJS_KEY : "9LQhM9GRvXPHexJZYq6W",
//
//     // apexheartcare.com  '8JJ9A6eCpr4Txx6tx880',
//     // 192.168.21.60 '6lZyB45Mi2LYcJcy618X",
// }





// const apiConfig = {
//   API_URL : "https://app.apexheartcare.com:8044",
//   REACT_APP_AUTHENTICATION : "https://app.apexheartcare.com:8044/action",
//   REACT_APP_BASE_API: "https://app.apexheartcare.com:8044/action",
//   REACT_APEX_BASE_API: "https://app.apexheartcare.com:8044",
//   PDFJS_KEY : "8JJ9A6eCpr4Txx6tx880",

//   // apexheartcare.com  '8JJ9A6eCpr4Txx6tx880',
//   // 192.168.21.60 '6lZyB45Mi2LYcJcy618X",
// }

// const apiConfig = {
//     API_URL : "https://localhost:8044",
//     REACT_APP_AUTHENTICATION : "https://localhost:8044/action",
//     REACT_APP_BASE_API: "https://localhost:8044/action",
//     REACT_APEX_BASE_API: "https://localhost:8044"
// }

function getStorageValue(key, defaultValue) {
    if (typeof window !== "undefined") {
        const saved = localStorage.getItem(key);
        if (saved === null) return defaultValue;

        try {
            return JSON.parse(saved);  // ✅ Parse JSON if possible
        } catch (error) {
            return saved;  // ✅ Return as plain text if not JSON
        }
    }
    return defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => getStorageValue(key, defaultValue));

    useEffect(() => {
        // ✅ Store as JSON if object/array, else store as plain text
        if (typeof value === "object") {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, value);
        }
    }, [key, value]);

    return [value, setValue];
};


export default apiConfig;

