// src/components/UserProfile.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper } from '@mui/material';
import { ApexUserDTO } from './userTypes';

const UserProfile: React.FC = () => {
    const [user, setUser] = useState<ApexUserDTO | null>(null);

    useEffect(() => {
        axios.get<ApexUserDTO>('/apexuser/me').then(res => setUser(res.data));
    }, []);

    if (!user) return null;

    return (
        <Paper elevation={3} sx={{ p: 2, maxWidth: 400 }}>
            <Typography variant="h6">User Profile</Typography>
            <Typography>Email: {user.userEmail}</Typography>
            <Typography>Subject: {user.userSubject}</Typography>
            <Typography>Name: {user.userFirstName} {user.userLastName}</Typography>
            <Typography>Roles: {user.userRoles}</Typography>
            <Typography>Status: {user.isActive ? 'Active' : 'Inactive'}</Typography>
        </Paper>
    );
};

export default UserProfile;