import React, { useState } from "react";
import { Button, CircularProgress, Avatar, Typography, Box, IconButton, Stack, Toolbar } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useUser } from "./context/UserContext";
import LoginAD from "./components/LoginAD";
import Schedule from "./components/Schedule";
import { CustomThemeProvider } from './themes';
import Layout from './components/Layout';
import { RingCentralDataProvider } from './context/RingCentralDataContext';


const App: React.FC = () => {
    const {
        user,
        accessToken,
        signIn,
        signInWithCustomFirebaseToken,
        signOutUser,
        minutesLeft,
    } = useUser();

    const [adLoginOpen, setAdLoginOpen] = useState(false);
    const [adLoading, setAdLoading] = useState(false);

    const handleADLogin = async (username: string, password: string) => {
        setAdLoading(true);
        try {
            const response = await fetch("https://app.apexheartcare.com:8044/action/auth/login", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: username, password }),
            });

            const data = await response.json();
            if (data.customToken) {
                await signInWithCustomFirebaseToken(data.customToken);
                setAdLoginOpen(false);
            } else {
                console.error("No customToken received from AD login endpoint");
            }
        } catch (error) {
            console.error("AD Login Failed:", error);
        } finally {
            setAdLoading(false);
        }
    };

    return (
        <RingCentralDataProvider>
        <Layout />
        </RingCentralDataProvider>
    );
};

export default App;
