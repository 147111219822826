// src/components/LoadingOverlay.tsx
import React from 'react';
import { Box, CircularProgress, Fade } from '@mui/material';

const LoadingOverlay: React.FC = () => (
    <Fade in>
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'rgba(255,255,255,0.75)',
                zIndex: 1300,
            }}
        >
            <CircularProgress />
        </Box>
    </Fade>
);

export default LoadingOverlay;
