// SMSListPage.tsx - Displays SMS messages grouped by conversation with Teams-style chat cards

import React, { useState, useMemo } from 'react';
import {
    Box,
    Typography,
    Paper,
    List,
    ListItemButton,
    Divider,
    Grid,
} from '@mui/material';
import { useRingCentralData } from '../context/RingCentralDataContext';

const SMSListPage = () => {
    const { smsMessages } = useRingCentralData(); // ✅ Match the correct property from context
    const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null);

    const groupedConversations = useMemo(() => {
        const groups: { [key: string]: any[] } = {};
        smsMessages.forEach((msg: any) => {
            if (!groups[msg.conversationId]) groups[msg.conversationId] = [];
            groups[msg.conversationId].push(msg);
        });
        Object.values(groups).forEach(group => group.sort((a, b) => new Date(a.rowDate).getTime() - new Date(b.rowDate).getTime()));
        return groups;
    }, [smsMessages]);

    const renderChatBubble = (msg: any, isFromSender: boolean) => {
        let fromName = msg.fromNumber;
        let toName = msg.toNumber;
        try {
            const json = JSON.parse(msg.jsonText);
            if (json.from?.name) fromName = `${json.from.name} (${json.from.phoneNumber})`;
            if (json.to?.[0]?.name) toName = `${json.to[0].name} (${json.to[0].phoneNumber})`;
        } catch {}

        return (
            <Box key={msg.id} display="flex" justifyContent={isFromSender ? 'flex-end' : 'flex-start'} my={1}>
                <Paper
                    elevation={2}
                    sx={{
                        maxWidth: '75%',
                        p: 1.5,
                        bgcolor: isFromSender ? '#DCF8C6' : '#FFF',
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="caption" color="textSecondary">
                        {isFromSender ? fromName : toName} — {new Date(msg.rowDate).toLocaleString()}
                    </Typography>
                    <Typography variant="body1">{msg.trantext}</Typography>
                </Paper>
            </Box>
        );
    };

    return (
        <Box display="flex" height="calc(100vh - 64px)">
            <Box width="35%" overflow="auto" pr={2} borderRight="1px solid #ccc">
                <List>
                    {Object.entries(groupedConversations).map(([conversationId, messages]) => (
                        <React.Fragment key={conversationId}>
                            <ListItemButton selected={selectedConversationId === conversationId} onClick={() => setSelectedConversationId(conversationId)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">
                                            {messages[0].fromNumber} → {messages[0].toNumber}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {new Date(messages[0].rowDate).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </Box>

            <Box width="65%" p={2} overflow="auto">
                {selectedConversationId ? (
                    groupedConversations[selectedConversationId].map((msg: any) =>
                        renderChatBubble(msg, msg.direction !== 'Inbound')
                    )
                ) : (
                    <Typography variant="body2" color="textSecondary">Select a conversation to view messages.</Typography>
                )}
            </Box>
        </Box>
    );
};

export default SMSListPage;