import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, CircularProgress, List, ListItem, ListItemText, Divider } from '@mui/material';
import ContextTasks from './tasks/ContextTasks';  // Import the ContextTasks component
import PostChatMessage from './tasks/PostChatMessage';  // Import PostChatMessage component
import { TaskDTO } from './tasks/TaskBoard';    // Import TaskDTO type
import { useUser } from '../context/UserContext';
import axiosApexInstance from '../auth/axiosApexInstance';

interface ContextPanelProps {
    contextType: 'fax' | 'voicemail';
    contextId: number;
    userName: string;
    userId: number;
}

const ContextPanel: React.FC<ContextPanelProps> = ({ contextType, contextId, userName, userId }) => {
    const { apexUser } = useUser();
    const [selectedTask, setSelectedTask] = useState<TaskDTO | null>(null); // Store the selected task
    const [taskFilter, setTaskFilter] = useState<string>('all'); // Filter for chat messages
    const [messages, setMessages] = useState<any[]>([]); // Store messages
    const [loading, setLoading] = useState<boolean>(false); // Loading state for messages

    // Fetch messages based on filter and task selection
    useEffect(() => {
        setLoading(true);
        fetchMessages();
    }, [taskFilter, selectedTask]);

    const fetchMessages = async () => {
        try {
            const params: any = {
                contextType,
                contextId,
            };

            if (taskFilter === 'selectedTask' && selectedTask) {
                params.taskId = selectedTask.taskId; // Fetch messages for the selected task
            }

            const res = await axiosApexInstance.get('/chat/messages', { params });
            setMessages(res.data);
        } catch (error) {
            console.error("Error fetching messages:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={2}>
            <Grid container spacing={1}>
                {/* Context Info */}
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" gutterBottom>
                            {contextType.toUpperCase()} #{contextId} - Tasks & Chat
                        </Typography>

                        {/* Dropdown to filter messages */}
                        <FormControl variant="outlined" size="small">
                            <InputLabel>Filter</InputLabel>
                            <Select
                                value={taskFilter}
                                onChange={(e) => setTaskFilter(e.target.value)}
                                label="Filter"
                            >
                                <MenuItem value="all">All Messages</MenuItem>
                                <MenuItem value="chatOnly">Chat Only</MenuItem>
                                <MenuItem value="selectedTask">Selected Task</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {/* Tasks Grid - Select a task */}
                <Grid item xs={12} md={12}>
                    <ContextTasks
                        contextType={contextType}
                        contextId={contextId}
                        onSelectTask={setSelectedTask}  // Update selected task
                    />
                </Grid>
            </Grid>

            {/* Post a Chat Message */}
            <Grid item xs={12} md={12}>
                <PostChatMessage contextType={contextType} contextId={contextId} />
            </Grid>

            {/* Message History */}
            <Box mt={2}>
                <Typography variant="h6">Messages</Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <List dense sx={{ maxHeight: 300, overflowY: 'auto', mt: 2 }}>
                        {messages.map((msg, i) => (
                            <React.Fragment key={i}>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1">
                                                {msg.actByName} <span style={{ fontSize: '0.8em', color: 'gray' }}>
                          ({new Date(msg.actDate).toLocaleString()})
                        </span>
                                            </Typography>
                                        }
                                        secondary={<Typography variant="body2">{msg.actNotes}</Typography>}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </Box>
        </Box>
    );
};

export default ContextPanel;
