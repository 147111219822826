import { ThemeOptions } from '@mui/material';

export const summerTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#fdd835', // sunflower yellow
        },
        secondary: {
            main: '#ffb300', // amber
        },
        background: {
            default: '#fffde7', // light yellow
            paper: '#fff9c4',
        },
        text: {
            primary: '#4e342e', // deep brown
            secondary: '#795548',
        },
    },
};
