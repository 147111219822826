export const handleEcwDocument = async (data, apiURL, accessToken, setpdfToDisplayURL, setstartingPageNum) => {
    if (!data.filename) return;

    let documentFilePath = data.dirpath ? `${data.dirpath}/${data.filename}` : `${data.filename}`;
    let startingpagenum = data.startpage || 1;

    let full_file_path = `${documentFilePath}`.replace('/', '\\');
    full_file_path = `${apiURL}/apexai/getfile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;

    const authHeader = {
        "Authorization": `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*"
    };

    fetch(encodeURI(full_file_path), { headers: authHeader })
        .then((r) => r.blob())
        .then((blob) => {
            const url = URL.createObjectURL(blob);
            setpdfToDisplayURL(url);
            setstartingPageNum(startingpagenum);
        });
};

export const handleFolderDocument = async (documentFilePath, apiURL, accessToken, setpdfToDisplayURL, setstartingPageNum) => {
    const authHeader = {
        "Authorization": `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*"
    };

    let full_file_path = `${documentFilePath}`.replace('/', '\\');
    full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;

    fetch(encodeURI(full_file_path), { headers: authHeader })
        .then((r) => r.blob())
        .then((blob) => {
            const url = URL.createObjectURL(blob);
            setpdfToDisplayURL(url);
            setstartingPageNum(1);
        });
};

export const handleAgDocument = async (data, apiURL, accessToken, setpdfToDisplayURL, setstartingPageNum) => {
    if (data.filename && (data.filename.includes('ecwupload') || data.filename.includes('ecwsrv'))) {
        handleFolderDocument(data.fileName, apiURL, accessToken, setpdfToDisplayURL, setstartingPageNum);
    } else {
        handleEcwDocument(data, apiURL, accessToken, setpdfToDisplayURL, setstartingPageNum);
    }
};
