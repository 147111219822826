// src/components/tasks/TaskTrayWindow.tsx
import React from 'react';
import { Paper, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserTask } from '../../hooks/useTasks';

export default function TaskTrayWindow({ task, onClose }: { task: UserTask, onClose: () => void }) {
    return (
        <Paper sx={{ width: 300, height: 250, p: 2, position: 'absolute', bottom: 64, right: 100, zIndex: 1200 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1">{task.title}</Typography>
                <IconButton size="small" onClick={onClose}><CloseIcon /></IconButton>
            </Box>
            <Typography variant="body2" sx={{ mt: 1 }}>{task.description}</Typography>
        </Paper>
    );
}
