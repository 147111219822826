import { initializeApp } from "firebase/app";
import { signInWithCustomToken } from "firebase/auth";
import { getAuth, signInWithPopup, GoogleAuthProvider, User } from "firebase/auth";

// Firebase Config (replace with your actual Firebase config)
const firebaseConfig = {
    apiKey: "AIzaSyAIjYPyychHs6sYAfAqkGqboe3z3eluoP8",
    authDomain: "apexapi-70fb2.firebaseapp.com",
    projectId: "apexapi-70fb2",
    storageBucket: "apexapi-70fb2.firebasestorage.app",
    messagingSenderId: "783941567642",
    appId: "1:783941567642:web:e57ffcda5fffc066914b23",
    measurementId: "G-TDHP7XENG9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, signInWithPopup, signInWithCustomToken, provider };
export type { User };

