// RingCentralDataContext.tsx - Manages SMS, Fax, Voicemail data from /getdata API

import React, { createContext, useContext, useEffect, useState, ReactNode, useCallback } from 'react';
import axios from 'axios';
import apiConfig from "../apiConfig";
import { useUser } from "./UserContext";

export interface RingCentralDataItem {
    id: number;
    rowStatus: string;
    rowDate: string;
    rowType: string;
    rowSubtype: string;
    callAction: string;
    callResult: string;
    companyId: string;
    extensionId: string;
    messageId: string;
    trantext: string;
    fromNumber: string;
    toNumber: string;
    filepath: string;
    recordingId: string;
    conversationId: string;
    tranId: string;
    jsonText: string;
    createdAt: string;
    hookAt: string;
    readyAt: string;
}

interface RingCentralDataContextType {
    allData: RingCentralDataItem[];
    smsMessages: RingCentralDataItem[];
    voicemails: RingCentralDataItem[];
    callog:  RingCentralDataItem[];
    faxes: RingCentralDataItem[];
    refreshData: () => Promise<void>;
    clearData: () => void;
}

const RingCentralDataContext = createContext<RingCentralDataContextType | undefined>(undefined);
const API_URL = `${apiConfig.REACT_APEX_BASE_API}`;

export const RingCentralDataProvider = ({ children }: { children: ReactNode }) => {
    const [allData, setAllData] = useState<RingCentralDataItem[]>([]);
    const { accessToken } = useUser();

    const loadData = useCallback(async () => {
        if (!accessToken) return;

        try {
            const response = await axios.get(`${API_URL}/rcdata/getdata`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            setAllData(response.data);
        } catch (error) {
            console.error('Failed to load RingCentral data', error);
        }
    }, [accessToken]);

    const clearData = () => {
        setAllData([]);
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    const smsMessages = allData.filter(d => d.rowType === 'message-store' && d.rowSubtype === 'SMS');
    const voicemails = allData.filter(d => d.rowType === 'message-store' && d.rowSubtype === 'VoiceMail');
    const faxes = allData.filter(d => d.rowType === 'message-store' && d.rowSubtype === 'Fax');
    const callog = allData.filter(d => d.rowType === 'calllog' );

    return (
        <RingCentralDataContext.Provider value={{
            allData,
            smsMessages,
            voicemails,
            faxes,
            callog,
            refreshData: loadData,
            clearData,
        }}>
            {children}
        </RingCentralDataContext.Provider>
    );
};

export const useRingCentralData = (): RingCentralDataContextType => {
    const context = useContext(RingCentralDataContext);
    if (!context) {
        throw new Error('useRingCentralData must be used within a RingCentralDataProvider');
    }
    return context;
};
