import React, { useState } from 'react';
import { Box, Typography, TextField, Select, MenuItem, Button } from '@mui/material';
import { useTasks } from '../hooks/useTasks';
import TaskActivityPanel from './TaskActivityPanel';

interface TaskPanelProps {
    onClose: () => void;
    onCreated: (taskId: number) => void;
    initialComment?: string;
}

const TaskPanel: React.FC<TaskPanelProps> = ({ onClose, onCreated, initialComment = '' }) => {
    const { createTask, addActivity } = useTasks();

    const [status, setStatus] = useState('new');
    const [assignedTo, setAssignedTo] = useState('');
    const [comment, setComment] = useState(initialComment);
    const [dueDate, setDueDate] = useState('');
    const [taskId, setTaskId] = useState<number | null>(null);

    const handleCreate = async () => {
        const task = await createTask({
            title: comment.slice(0, 30) || 'New Task',
            status,
            assignedTo,
            dueDate: dueDate ? `${dueDate}T00:00:00` : undefined,
            description: comment,
            createdBy: assignedTo,
        });

        if (!task?.id) {
            console.error("❌ Task creation failed: missing task.id");
            return;
        }

        await addActivity(task.id, {
            taskId: task.id,
            activityText: comment,
            createdBy: assignedTo,
        });

        setTaskId(task.id);
        onCreated(task.id);
    };

    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ width: 400, p: 2, backgroundColor: 'white', boxShadow: 3 }}>
                <Typography variant="h6">New Task</Typography>

                <TextField
                    label="Assigned To"
                    value={assignedTo}
                    onChange={(e) => setAssignedTo(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                />

                <TextField
                    label="Due Date"
                    type="date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    sx={{ mt: 2 }}
                />

                <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    <MenuItem value="new">New</MenuItem>
                    <MenuItem value="in_progress">In Progress</MenuItem>
                    <MenuItem value="done">Done</MenuItem>
                    <MenuItem value="abandoned">Abandoned</MenuItem>
                </Select>

                <TextField
                    label="Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    multiline
                    rows={3}
                    fullWidth
                    sx={{ mt: 2 }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreate}
                    sx={{ mt: 2 }}
                >
                    Create Task
                </Button>
            </Box>

            {taskId && (
                <Box sx={{ flex: 1, backgroundColor: '#fafafa', p: 2 }}>
                    <TaskActivityPanel taskId={taskId} />
                </Box>
            )}
        </Box>
    );
};

export default TaskPanel;