import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    auth,
    provider,
    signInWithPopup,
    signInWithCustomToken,
    User,
} from '../auth/firebase';
import {
    getIdTokenResult,
    onAuthStateChanged,
    signOut,
} from 'firebase/auth';
import axios from 'axios';
import apiConfig from "../apiConfig";
import { setAccessToken, setUnauthorizedLogger } from '../auth/axiosApexInstance';

export interface ApexUserDTO {
    userId: number;
    userEcwid: number;
    userEmail: string;
    userSubject: string;
    userFirstName: string;
    userLastName: string;
    userRoles: string;
    createdAt: string;
    delFlag: number;
    isActive: number;
}

interface UnauthorizedLog {
    url: string;
    time: string;
}

interface AuthState {
    user: User | null;
    accessToken: string | null;
    refreshToken: string | null;
    lastAuthTime: number | null;
    expiresAt: number | null;
    apexUser: ApexUserDTO | null;
    signIn: () => Promise<void>;
    signInWithCustomFirebaseToken: (token: string) => Promise<void>;
    signOutUser: () => Promise<void>;
    refreshAccessToken: () => Promise<void>;
    refreshCustomToken: () => Promise<void>;
    minutesLeft: () => number | null;
    unauthorizedLog: UnauthorizedLog[];
    addUnauthorizedLog: (url: string) => void;
}

const UserContext = createContext<AuthState | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [accessToken, setAccessTokenState] = useState<string | null>(null);
    const [refreshToken, setRefreshToken] = useState<string | null>(null);
    const [lastAuthTime, setLastAuthTime] = useState<number | null>(null);
    const [expiresAt, setExpiresAt] = useState<number | null>(null);
    const [apexUser, setApexUser] = useState<ApexUserDTO | null>(null);
    const [unauthorizedLog, setUnauthorizedLog] = useState<UnauthorizedLog[]>([]);

    const addUnauthorizedLog = (url: string) => {
        const timestamp = new Date().toLocaleString();
        setUnauthorizedLog(prev => [...prev, { url, time: timestamp }]);
    };

    const storeAuthData = async (data: any) => {
        const cache = await caches.open('auth-cache');
        await cache.put('user-auth', new Response(JSON.stringify(data)));
    };

    const handleFirebaseLogin = async (firebaseUser: User) => {
        const idTokenResult = await getIdTokenResult(firebaseUser);
        const expirationTimestamp = idTokenResult.claims.exp ? Number(idTokenResult.claims.exp) * 1000 : Date.now() + 3600000;

        const newAuthData = {
            user: firebaseUser,
            accessToken: idTokenResult.token,
            refreshToken: await firebaseUser.getIdToken(true),
            lastAuthTime: Date.now(),
            expiresAt: expirationTimestamp,
        };

        setUser(firebaseUser);
        setAccessTokenState(newAuthData.accessToken);
        setRefreshToken(newAuthData.refreshToken);
        setLastAuthTime(newAuthData.lastAuthTime);
        setExpiresAt(newAuthData.expiresAt);

        await storeAuthData(newAuthData);
    };

    const signIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            await handleFirebaseLogin(result.user);
        } catch (error) {
            console.error('Google sign-in error:', error);
        }
    };

    const signInWithCustomFirebaseToken = async (customToken: string) => {
        try {
            const result = await signInWithCustomToken(auth, customToken);
            await handleFirebaseLogin(result.user);
        } catch (error) {
            console.error('Custom token sign-in error:', error);
        }
    };

    const signOutUser = async () => {
        await signOut(auth);
        setUser(null);
        setAccessTokenState(null);
        setRefreshToken(null);
        setLastAuthTime(null);
        setExpiresAt(null);
        setApexUser(null);
        const cache = await caches.open('auth-cache');
        await cache.delete('user-auth');
    };

    const refreshAccessToken = async () => {
        if (!user) return;
        try {
            const newToken = await user.getIdToken(true);
            const idTokenResult = await getIdTokenResult(user);
            const expirationTimestamp = idTokenResult.claims.exp ? Number(idTokenResult.claims.exp) * 1000 : Date.now() + 3600000;

            setAccessTokenState(newToken);
            setExpiresAt(expirationTimestamp);

            await storeAuthData({
                user,
                accessToken: newToken,
                refreshToken,
                lastAuthTime,
                expiresAt: expirationTimestamp,
            });
        } catch (error) {
            console.error('Token refresh failed:', error);
        }
    };

    const refreshCustomToken = async () => {
        try {
            const res = await fetch('/auth/refresh', { method: 'POST', credentials: 'include' });
            if (!res.ok) throw new Error('Unable to refresh custom token');
            const { customToken } = await res.json();
            const result = await signInWithCustomToken(auth, customToken);
            await handleFirebaseLogin(result.user);
        } catch (error) {
            console.error('Custom token refresh failed:', error);
            await signOutUser();
        }
    };

    const minutesLeft = () => {
        if (!expiresAt) return null;
        return Math.round((expiresAt - Date.now()) / 60000);
    };

    useEffect(() => {
        const interval = setInterval(async () => {
            const minsLeft = minutesLeft();
            if (minsLeft !== null && minsLeft <= 5) {
                const isCustomAuth = user?.providerData?.[0]?.providerId === 'custom';
                isCustomAuth ? await refreshCustomToken() : await refreshAccessToken();
            }
        }, 60000);
        return () => clearInterval(interval);
    }, [expiresAt]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (!firebaseUser) return await signOutUser();
            await handleFirebaseLogin(firebaseUser);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (accessToken) {
            setAccessToken(accessToken); // for axios instance
            axios.get<ApexUserDTO>(`${apiConfig.REACT_APEX_BASE_API}/apexuser/me`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => setApexUser(res.data))
                .catch((err) => console.error('Failed to fetch apex user', err));
        }
    }, [accessToken]);

    useEffect(() => {
        setUnauthorizedLogger(addUnauthorizedLog); // wire into axios interceptor
    }, [addUnauthorizedLog]);

    return (
        <UserContext.Provider
            value={{
                user,
                accessToken,
                refreshToken,
                lastAuthTime,
                expiresAt,
                signIn,
                signInWithCustomFirebaseToken,
                signOutUser,
                refreshAccessToken,
                refreshCustomToken,
                minutesLeft,
                apexUser,
                unauthorizedLog,
                addUnauthorizedLog,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) throw new Error('useUser must be used within a UserProvider');
    return context;
};
