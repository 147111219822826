import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useTasks } from '../hooks/useTasks';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const TaskBoard = () => {
    const { tasks, loadTasks } = useTasks();

    useEffect(() => {
        loadTasks();
    }, []);

    return (
        <Box sx={{ height: '600px', width: '100%', p: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>📋 Task Board</Typography>
            <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                <AgGridReact rowData={tasks} columnDefs={[
                    { field: 'id', headerName: 'ID', maxWidth: 90 },
                    { field: 'title', headerName: 'Title', flex: 1 },
                    { field: 'status', headerName: 'Status', maxWidth: 150 },
                    { field: 'assignedTo', headerName: 'Assigned To', flex: 1 },
                    {
                        field: 'dueDate',
                        headerName: 'Due Date',
                        maxWidth: 160,
                        valueFormatter: (params: any) => params.value ? new Date(params.value).toLocaleDateString() : '',
                    },
                    { field: 'createdBy', headerName: 'Created By', flex: 1 },
                    {
                        field: 'createdAt',
                        headerName: 'Created At',
                        valueFormatter: (params: any) => params.value ? new Date(params.value).toLocaleString() : '',
                    },
                ]} domLayout="autoHeight" pagination={true} />
            </div>
        </Box>
    );
};

export default TaskBoard;