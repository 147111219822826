import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axiosApexInstance from '../../auth/axiosApexInstance'; // custom axios instance
import { TaskDTO } from './TaskBoard'; // TaskDTO type

interface PostTaskActionProps {
    selectedTask: TaskDTO | null;
    onPostSuccess: () => void;
}

const PostTaskAction: React.FC<PostTaskActionProps> = ({ selectedTask, onPostSuccess }) => {
    const [taskActionMessage, setTaskActionMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePostTaskAction = async () => {
        if (!selectedTask || !taskActionMessage) return;

        setLoading(true);

        const taskAction = {
            actTaskId: selectedTask.taskId,
            actNotes: taskActionMessage,
            actBy: `${selectedTask.assignedToName}`,  // Assuming `assignedToName` represents the person who is posting the task action
            notesBy: 'System', // You can replace this with the actual user posting the action
            updateAt: new Date().toISOString(),
            delFlag: 0,
        };

        try {
            // Call the endpoint to post the task action
            await axiosApexInstance.post(`/task/${selectedTask.taskId}/action`, taskAction);
            setTaskActionMessage('');
            onPostSuccess(); // Callback to refresh task actions
        } catch (error) {
            console.error('Failed to post task action:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={2}>
            <Typography variant="h6">Post Task Action for {selectedTask?.subject}</Typography>
            <TextField
                label="Task Action Message"
                fullWidth
                multiline
                rows={4}
                value={taskActionMessage}
                onChange={(e) => setTaskActionMessage(e.target.value)}
                placeholder="Enter task action..."
                disabled={loading}
            />
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    onClick={handlePostTaskAction}
                    disabled={loading || !taskActionMessage}
                >
                    {loading ? 'Posting...' : 'Post Action'}
                </Button>
            </Box>
        </Box>
    );
};

export default PostTaskAction;
