import axios, { isAxiosError, AxiosHeaders } from "axios";
import apiConfig from "../apiConfig";

let accessToken: string | null = null;
let logUnauthorizedUrl: ((url: string) => void) | null = null;

export const setAccessToken = (token: string | null) => {
    accessToken = token;
};

export const setUnauthorizedLogger = (fn: (url: string) => void) => {
    logUnauthorizedUrl = fn;
};

const axiosApexInstance = axios.create({
    baseURL: apiConfig.REACT_APEX_BASE_API,
});

// ✅ Safe header assignment without type errors
axiosApexInstance.interceptors.request.use((config) => {
    if (accessToken) {
        // Ensure headers object exists
        if (!config.headers) {
            config.headers = new AxiosHeaders();
        }

        if (config.headers instanceof AxiosHeaders) {
            config.headers.set("Authorization", `Bearer ${accessToken}`);
        } else {
            // fallback: cast to plain object type
            (config.headers as Record<string, string>)["Authorization"] = `Bearer ${accessToken}`;
        }
    }
    return config;
});

// ✅ Track 401 Unauthorized URLs
axiosApexInstance.interceptors.response.use(
    (res) => res,
    (error) => {
        if (isAxiosError(error) && error.response?.status === 401) {
            if (logUnauthorizedUrl) {
                logUnauthorizedUrl(error.config?.url || "unknown");
            }
        }
        return Promise.reject(error);
    }
);

export default axiosApexInstance;
