// src/themes/index.ts
import React, {
    createContext,
    useContext,
    useMemo,
    useState,
    ReactNode, FC,
} from 'react';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { springTheme } from './springTheme';
import { summerTheme } from './summerTheme';
import { fallTheme } from './fallTheme';
import { winterTheme } from './winterTheme';

export type ThemeName = 'spring' | 'summer' | 'fall' | 'winter';

const themeMap: Record<ThemeName, ThemeOptions> = {
    spring: springTheme,
    summer: summerTheme,
    fall: fallTheme,
    winter: winterTheme,
};

interface ThemeContextType {
    themeName: ThemeName;
    setThemeName: (name: ThemeName) => void;
}

const ThemeSwitcherContext = createContext<ThemeContextType>({
    themeName: 'spring',
    setThemeName: () => {},
});

export const useThemeSwitcher = () => useContext(ThemeSwitcherContext);

interface CustomThemeProviderProps {
    children: ReactNode;
}

export const CustomThemeProvider: FC<CustomThemeProviderProps> = ({ children }) => {

    const [themeName, setThemeName] = useState<ThemeName>(
        () => (localStorage.getItem('theme') as ThemeName) || 'spring'
    );

    const handleSetThemeName = (name: ThemeName) => {
        localStorage.setItem('theme', name);
        setThemeName(name);
    };

    const theme = useMemo(() => createTheme(themeMap[themeName]), [themeName]);

    return (
        <ThemeSwitcherContext.Provider value={{ themeName, setThemeName:  handleSetThemeName }}>
    <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
        </ThemeProvider>
        </ThemeSwitcherContext.Provider>
);
};