import React, { useState } from 'react';
import { Box, TextField, Button, CircularProgress, Typography } from '@mui/material';
import axiosApexInstance from '../../auth/axiosApexInstance';  // Custom axios instance
import { useUser } from '../../context/UserContext';

interface PostChatMessageProps {
    contextType: 'fax' | 'voicemail';
    contextId: number;
}

const PostChatMessage: React.FC<PostChatMessageProps> = ({ contextType, contextId }) => {
    const { apexUser } = useUser();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Handle sending chat messages
    const handleSendMessage = async () => {
        if (!message || !apexUser) return;

        try {
            setLoading(true);
            await axiosApexInstance.post(`/chat/${contextId}/reply`, {
                actNotes: message,
                actById: apexUser.userId || 0,
                actByName: `${apexUser.userFirstName} ${apexUser.userLastName}`,
                actDate: new Date().toISOString(),
                contextType,
                contextId,
            });
            setMessage('');  // Clear the message input after sending
        } catch (error) {
            console.error("Error sending chat message:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={2}>
            <Typography variant="h6">Post Chat Message for {contextType.toUpperCase()} #{contextId}</Typography>

            <Box mt={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type a chat message..."
                />
                <Button variant="contained" onClick={handleSendMessage} disabled={loading} sx={{ mt: 1 }}>
                    {loading ? <CircularProgress size={24} /> : "Send Chat Message"}
                </Button>
            </Box>
        </Box>
    );
};

export default PostChatMessage;
