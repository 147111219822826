import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    IconButton,
    TextField,
    Button,
    Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { useThreads } from '../../hooks/useThreads';
import MentionSelect from './MentionSelect';
import apiConfig from "../../apiConfig";

interface ThreadPanelProps {
    parentType: string;
    parentId: string;
    onClose: () => void;
}

const ThreadPanel: React.FC<ThreadPanelProps> = ({ parentType, parentId, onClose }) => {
    const { thread, messages, addReply, loadMessages } = useThreads(parentType, parentId);
    const [newMessage, setNewMessage] = useState('');
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        loadMessages();
    }, [parentType, parentId]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSend = async () => {
        if (newMessage.trim() && thread?.id) {
            await addReply(newMessage);
            setNewMessage('');
        }
    };

    return (
        <Paper
            elevation={6}
            sx={{
                width: 360,
                height: 400,
                p: 2,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                resize: 'both',
                overflow: 'hidden',
                border: '1px solid #ccc'
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                <Typography variant="subtitle1">
                    Thread: {parentType} {parentId}
                </Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>

            <Box
                ref={listRef}
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    mb: 2,
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    p: 1
                }}
            >
                {messages.length === 0 ? (
                    <Typography variant="body2" color="textSecondary">No messages yet.</Typography>
                ) : (
                    messages.map((msg, index) => (
                        <Box key={index} mb={1}>
                            <Typography variant="caption" color="textSecondary">
                                {msg.performedBy} @ {new Date(msg.timestamp).toLocaleString()}
                            </Typography>
                            <Typography variant="body2">{msg.message}</Typography>
                        </Box>
                    ))
                )}
            </Box>

            <Stack direction="row" spacing={1}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Write a message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <Button variant="contained" onClick={handleSend} endIcon={<SendIcon />}>
                    Send
                </Button>
            </Stack>
        </Paper>
    );
};

export default ThreadPanel;
