// Layout.tsx - Responsive App Layout with Collapsible Drawer, Task Tray, and Fixed Footer Icons with Drag and Hover

import React, {JSX, useState} from 'react';
import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    Menu,
    MenuItem,
    Avatar,
    Tooltip,
    useTheme,
    useMediaQuery,
    Collapse,
    Paper,
    Popover,
    Badge,
    Button, Stack, Fade
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/Inbox';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SmsIcon from '@mui/icons-material/Sms';
import ChatIcon from '@mui/icons-material/Chat';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import PrintIcon from '@mui/icons-material/Print';
import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import CallReceivedIcon from '@mui/icons-material/CallReceived';


import { ThemeSwitcher } from './ThemeSwitcher';
import LoginAD from "./LoginAD";
import { useUser } from '../context/UserContext';
import Schedule from './Schedule';
import VoiceMailListPage from './VoiceMailListPage';
import CallLogListPage from "./CallLogListPage";
import SMSListPage from './SMSListPage';
import FaxListPage from './FaxListPage';
import UserAvatarMenu from "./UserAvatarMenu";
import TaskTrayWindow from './tasks/TaskTrayWindow';
import { UserTask } from '../hooks/useTasks';
import { ChatThread } from '../hooks/useThreads';
import TaskBoard from './TaskBoard';
import ContextPanel from './ContextPanel';
import UserProfile from './UserProfile';
import LoadingOverlay from "./LoadingOverlay";
import EntryPage from "./EntryPage";

const drawerWidth = 240;
const collapsedDrawerWidth = 72;

const Layout: React.FC = () => {
    const theme = useTheme();
    const { apexUser } = useUser();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [mobileOpen, setMobileOpen] = useState(false);
    const [drawerCollapsed, setDrawerCollapsed] = useState(false);
    const [trayPinned, setTrayPinned] = useState(true);
    const [trayAnchorEl, setTrayAnchorEl] = useState<null | HTMLElement>(null);
    const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);

    const [openTasks, setOpenTasks] = useState<UserTask[]>([]);
    const [openThreads, setOpenThreads] = useState<ChatThread[]>([]);

    const [openTask, setOpenTask] = useState<UserTask | null>(null);

    const [currentPage, setCurrentPage] = useState<string>('Schedule');

    const pageComponents: Record<string, JSX.Element> = {
        Schedule: <Schedule />,
        SMS: <SMSListPage />,
        Voicemail: <VoiceMailListPage />,
        CallLog: <CallLogListPage />,
        Fax: <FaxListPage />,
        Tasks: <TaskBoard />,
        ChatFax123: <ContextPanel contextType="fax" contextId={123} userName={`${apexUser?.userFirstName} ${apexUser?.userLastName}`} userId={apexUser?.userId || 0} />,
        ChatVm456: <ContextPanel contextType="voicemail" contextId={456} userName={`${apexUser?.userFirstName} ${apexUser?.userLastName}`} userId={apexUser?.userId || 0} />,
        Profile: <UserProfile />,
    };

    type PriorityLevel = 'critical' | 'high' | 'normal' | 'low';

    const priorityColorMap: Record<PriorityLevel, 'error' | 'warning' | 'primary' | 'default'> = {
        critical: 'error',
        high: 'warning',
        normal: 'primary',
        low: 'default',
    };

    const [trayItems, setTrayItems] = useState<
        { icon: React.ReactNode; label: string; description: string; priority: PriorityLevel }[]
    >([
        { icon: <SmsIcon />, label: 'SMS from JBritton call him urgently as soon as possible', description: 'New message', priority: 'critical' },
        { icon: <ChatIcon />, label: 'Chat DSmith', description: 'Support reply', priority: 'high' },
        { icon: <VoicemailIcon />, label: 'Voicemail NBhatt', description: 'Voicemail', priority: 'normal' },
        { icon: <PrintIcon />, label: 'Fax', description: 'Fax received', priority: 'low' },
        { icon: <PhoneIcon />, label: 'Call', description: 'Follow-up call', priority: 'normal' },
        { icon: <AssignmentIcon />, label: 'Other Task1', description: 'More to do', priority: 'high' },
        { icon: <AssignmentIcon />, label: 'Other Task2', description: 'More to do', priority: 'high' },
        { icon: <AssignmentIcon />, label: 'Other Task 3', description: 'More to do', priority: 'high' },
        { icon: <AssignmentIcon />, label: 'Other Task 4 with longer desc', description: 'More to do', priority: 'high' },
        { icon: <AssignmentIcon />, label: 'Other Task 5', description: 'More to do', priority: 'high' },
    ]);

    const [trayOverflowAnchorEl, setTrayOverflowAnchorEl] = useState<null | HTMLElement>(null);
    const visibleTrayItems = trayItems.slice(0, 5);
    const overflowTrayItems = trayItems.slice(5);

    const [dragIndex, setDragIndex] = useState<number | null>(null);

    const navItems = [
        { key: 'Schedule', icon: <AssignmentIcon />, label: 'Schedule' },
        { key: 'SMS', icon: <SmsIcon />, label: 'Sms' },
        { key: 'Voicemail', icon: <VoicemailIcon />, label: 'Voicemail' },
        { key: 'CallLog', icon: <CallReceivedIcon />, label: 'Call Log' },
        { key: 'Fax', icon: <PrintIcon />, label: 'Fax' },
        { key: 'Tasks', icon: <InboxIcon />, label: 'Tasks' },
        { key: 'ChatFax123', icon: <ChatIcon />, label: 'Fax Chat #123' },
        { key: 'ChatVm456', icon: <ChatIcon />, label: 'VM Chat #456' },
        { key: 'Profile', icon: <Avatar />, label: 'Profile' },
    ];


    const footerItems = [
        { key: 'tray', icon: trayPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />, label: 'Tray', onClick: (e: React.MouseEvent) => setTrayAnchorEl(e.currentTarget as HTMLElement) },
        { key: 'settings', icon: <SettingsIcon />, label: 'Settings' },
        { key: 'about', icon: <InfoOutlinedIcon />, label: 'About' },
        { key: 'theme', icon: <ThemeSwitcher />, label: '' },
        { key: 'help', icon: <HelpOutlineIcon />, label: 'Help' },
    ];

    const visibleFooterItems = footerItems.slice(0, 2);
    const overflowFooterItems = footerItems.slice(2);

    const {
        user,
        accessToken,
        signIn,
        signInWithCustomFirebaseToken,
        signOutUser,
        minutesLeft,
    } = useUser();

    const [adLoginOpen, setAdLoginOpen] = useState(false);
    const [adLoading, setAdLoading] = useState(false);

    const openTaskWindow = (task: UserTask) => {
        setOpenTasks((prev) =>
            prev.find((t) => t.id === task.id) ? prev : [...prev, task]
        );
    };

    const closeTaskWindow = (taskId: number) => {
        setOpenTasks((prev) => prev.filter((t) => t.id !== taskId));
    };

    const openThreadWindow = (thread: ChatThread) => {
        setOpenThreads((prev) =>
            prev.find((t) => t.id === thread.id) ? prev : [...prev, thread]
        );
    };

    const closeThreadWindow = (threadId: number) => {
        setOpenThreads((prev) => prev.filter((t) => t.id !== threadId));
    };


    const handleADLogin = async (username: string, password: string) => {
        setAdLoading(true);
        try {
            const response = await fetch('https://app.apexheartcare.com:8044/action/auth/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: username, password }),
            });
            const data = await response.json();
            if (data.customToken) {
                await signInWithCustomFirebaseToken(data.customToken);
                setAdLoginOpen(false);
            } else {
                console.error('No customToken received from AD login endpoint');
            }
        } catch (error) {
            console.error('AD Login Failed:', error);
        } finally {
            setAdLoading(false);
        }
    };

    const handleDrawerToggle = () => {
        if (isMobile) {
            setMobileOpen(!mobileOpen);
        } else {
            setDrawerCollapsed(!drawerCollapsed);
        }
    };

    const handleToggleTrayPinned = () => {
        setTrayPinned(!trayPinned);
        setTrayAnchorEl(null);
    };

    const handleDragStart = (index: number) => setDragIndex(index);
    const handleDragEnter = (index: number) => {
        if (dragIndex === null || dragIndex === index) return;
        const reordered = [...trayItems];
        const [removed] = reordered.splice(dragIndex, 1);
        reordered.splice(index, 0, removed);
        setTrayItems(reordered);
        setDragIndex(index);
    };

    const drawerContent = (
        <Box display="flex" flexDirection="column" height="100%">
            <Box>
                <Toolbar
                    sx={{ justifyContent: drawerCollapsed ? 'center' : 'space-between', px: drawerCollapsed ? 1 : 2 }}
                >
                    {!drawerCollapsed && <Typography variant="h6">Menu</Typography>}
                    <IconButton onClick={handleDrawerToggle}>
                        {drawerCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </Toolbar>
                <Divider />
                <List sx={{ overflowY: 'auto', flexGrow: 1 }}>
                    {navItems.map((item) => (
                        <Tooltip key={item.key} title={item.label} arrow placement="right">
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    onClick={() => setCurrentPage(item.key)}
                                    sx={{ justifyContent: drawerCollapsed ? 'center' : 'initial', px: 2.5 }}
                                >
                                    <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>{item.icon}</ListItemIcon>
                                    {!drawerCollapsed && <ListItemText primary={item.label} />}
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>
            </Box>
            <Divider />
            <Box sx={{ mt: 'auto' }}>
                {visibleFooterItems.map((item) => (
                    <ListItem disablePadding key={item.key} sx={{ display: 'block' }}>
                        <ListItemButton onClick={item.onClick} sx={{ justifyContent: drawerCollapsed ? 'center' : 'initial', px: 2.5 }}>
                            <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>{item.icon}</ListItemIcon>
                            {!drawerCollapsed && <ListItemText primary={item.label} />}
                        </ListItemButton>
                    </ListItem>
                ))}
                {overflowFooterItems.length > 0 && (
                    <ListItem disablePadding>
                        <ListItemButton onClick={(e) => setMoreAnchorEl(e.currentTarget as HTMLElement)} sx={{ justifyContent: drawerCollapsed ? 'center' : 'initial', px: 2.5 }}>
                            <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                                <MoreHorizIcon />
                            </ListItemIcon>
                            {!drawerCollapsed && <ListItemText primary="More" />}
                        </ListItemButton>
                    </ListItem>
                )}
                <Popover
                    open={Boolean(moreAnchorEl)}
                    anchorEl={moreAnchorEl}
                    onClose={() => setMoreAnchorEl(null)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <List>
                        {overflowFooterItems.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Popover>
                <Popover
                    open={Boolean(trayAnchorEl)}
                    anchorEl={trayAnchorEl}
                    onClose={() => setTrayAnchorEl(null)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <List>
                        {trayItems.map((item, index) => (
                            <Tooltip key={index} title={item.description} arrow placement="right">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{ px: 1 }}
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragEnter={() => handleDragEnter(index)}
                                >
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            const newItems = trayItems.filter((_, i) => i !== index);
                                            setTrayItems(newItems);
                                        }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}>
                                        {item.icon}
                                        <Typography variant="body2">{item.label}</Typography>
                                    </Box>
                                </Box>
                            </Tooltip>
                        ))}
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleToggleTrayPinned}>
                                <ListItemIcon>{trayPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}</ListItemIcon>
                                <ListItemText primary={trayPinned ? 'Unpin Tray' : 'Pin Tray'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Popover>
            </Box>
        </Box>
    );

    if (!user) {
        return (
            <>
                {adLoading && <LoadingOverlay />}
                <EntryPage
                    onGoogleLogin={signIn}
                    onADLogin={handleADLogin}
                    adLoading={adLoading}
                    adLoginOpen={adLoginOpen}
                    setAdLoginOpen={setAdLoginOpen}
                />
            </>
        );
    }

    return (
        <Fade in timeout={500}>
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <CssBaseline />
            <AppBar position="fixed" color="primary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: 48, justifyContent: 'center' }}>
                <Toolbar variant="dense" sx={{ minHeight: '48px !important' }}>
                    <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'inline-flex', xs: 'none' } }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="subtitle1" noWrap sx={{ flexGrow: 1 }}>
                        Clinic Workflow Manager
                    </Typography>
                    {user ? (
                        <>
                            <Typography variant="body2" sx={{ mr: 2 }}>
                                Welcome, {user.displayName || user.email}
                            </Typography>
                            <Typography variant="caption" sx={{ mr: 2 }}>
                                {minutesLeft() !== null ? `${minutesLeft()} min` : "..."}
                            </Typography>
                            <IconButton color="inherit" onClick={signOutUser} title="Logout">
                                <LogoutIcon />
                            </IconButton>
                        </>
                    ) : (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={signIn}
                                disabled={adLoading}
                            >
                                {adLoading ? <CircularProgress size={20} /> : 'Google'}
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={() => setAdLoginOpen(true)}
                                disabled={adLoading}
                            >
                                Apex AD
                            </Button>
                        </Stack>
                    )}
                    <Box sx={{ ml: 2 }}>
                        <UserAvatarMenu />
                    </Box>
                </Toolbar>
            </AppBar>

            <Box component="nav" sx={{ width: { sm: drawerCollapsed ? collapsedDrawerWidth : drawerWidth }, flexShrink: 0 }} aria-label="sidebar">
                <Drawer
                    variant={isMobile ? 'temporary' : 'permanent'}
                    open={isMobile ? mobileOpen : true}
                    onClose={() => setMobileOpen(false)}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: drawerCollapsed ? collapsedDrawerWidth : drawerWidth,
                            boxSizing: 'border-box',
                            overflowX: 'hidden',
                            transition: 'width 0.3s',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        },
                    }}
                >
                    {drawerContent}
                </Drawer>
            </Box>

            <Box component="main" sx={{ flexGrow: 1, mt: '48px', overflow: 'hidden', position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                        {pageComponents[currentPage]}
                    </Box>
                </Box>
                {trayPinned && (
                    <Paper elevation={3} sx={{ height: 56, px: 1, display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            {visibleTrayItems.map((item, index) => (
                                <Tooltip key={index} title={item.description} arrow>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        sx={{
                                            px: 1,
                                            width: `${100 / 5}%`,
                                            minWidth: 0,
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                        }}
                                        draggable
                                        onDragStart={() => handleDragStart(index)}
                                        onDragEnter={() => handleDragEnter(index)}
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                const newItems = trayItems.filter((_, i) => i !== index);
                                                setTrayItems(newItems);
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                        <Badge
                                            variant="dot"
                                            color={priorityColorMap[item.priority]}
                                            overlap="circular"
                                            sx={{ mr: 0.5 }}
                                        >
                                            {item.icon}
                                        </Badge>
                                        <Typography variant="body2" noWrap>{item.label}</Typography>
                                        <Badge
                                            variant="dot"
                                            color={priorityColorMap[item.priority]}
                                            overlap="circular"
                                            sx={{ mr: 0.5 }}
                                        >
                                            <Box
                                                onClick={() => {
                                                    // This could be dynamic later by item.type === 'task'
                                                    setOpenTask({ id: 1, title: item.label, status: 'New', assignedTo: 'me' });
                                                }}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                {item.icon}
                                            </Box>
                                        </Badge>
                                    </Box>
                                </Tooltip>
                            ))}
                            {overflowTrayItems.length > 0 && (
                                <IconButton size="small" onClick={(e) => setTrayOverflowAnchorEl(e.currentTarget)}>
                                    <MoreHorizIcon />
                                </IconButton>
                            )}
                            {openTask && (
                                <TaskTrayWindow task={openTask} onClose={() => setOpenTask(null)} />
                            )}

                            <Popover
                                open={Boolean(trayOverflowAnchorEl)}
                                anchorEl={trayOverflowAnchorEl}
                                onClose={() => setTrayOverflowAnchorEl(null)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <List>
                                    {overflowTrayItems.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <Tooltip title={item.description} arrow placement="right">
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <Badge
                                                            variant="dot"
                                                            color={priorityColorMap[item.priority]}
                                                            overlap="circular"
                                                        >
                                                            {item.icon}
                                                        </Badge>
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.label} />
                                                    <IconButton size="small" onClick={() => {
                                                        const newItems = trayItems.filter((_, i) => i !== index + 5);
                                                        setTrayItems(newItems);
                                                    }}>
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </ListItemButton>
                                            </Tooltip>
                                        </ListItem>
                                    ))}
                                </List>
                            </Popover>
                        </Box>
                        <IconButton size="small" onClick={() => setTrayPinned(false)}>
                            <PushPinIcon fontSize="small" />
                        </IconButton>
                    </Paper>
                )}
            </Box>
            <LoginAD
                open={adLoginOpen}
                onClose={() => setAdLoginOpen(false)}
                onLogin={handleADLogin}
                loading={adLoading}
            />
        </Box>
        </Fade>
    );
};

export default Layout;