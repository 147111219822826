import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { TaskDTO } from './TaskBoard';
import axiosApexInstance from '../../auth/axiosApexInstance';
import TaskForm from "./TaskForm";  // ✅ custom instance
import PostTaskAction from './PostTaskAction';  // Import PostTaskAction component

interface ContextTasksProps {
    contextType: 'fax' | 'voicemail' | ''; // Allow blank value for no task
    contextId: number;
    onSelectTask: React.Dispatch<React.SetStateAction<TaskDTO | null>>; // Prop to update the selected task
}

const ContextTasks: React.FC<ContextTasksProps> = ({ contextType, contextId, onSelectTask }) => {
    const [tasks, setTasks] = useState<TaskDTO[]>([]); // List of tasks
    const [openForm, setOpenForm] = useState(false);
    const [selectedTask, setSelectedTask] = useState<TaskDTO | null>(null); // Track selected task

    // Fetch tasks related to contextType and contextId
    const fetchContextTasks = async () => {
        try {
            const res = await axiosApexInstance.get<TaskDTO[]>('/task', {
                params: { contextType, contextId },
            });
            setTasks(res.data);
        } catch (error) {
            console.error('Failed to fetch tasks:', error);
        }
    };

    useEffect(() => {
        fetchContextTasks(); // Fetch tasks on mount or context changes
    }, [contextType, contextId]);

    const handleTaskSelect = (task: TaskDTO | null) => {
        setSelectedTask(task); // Update selected task
        onSelectTask(task); // Notify parent component with selected task
    };

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Tasks for {contextType} #{contextId}</Typography>
                <Button variant="contained" onClick={() => setOpenForm(true)}>+ New Task</Button>
            </Box>

            {/* Task Grid */}
            <div className="ag-theme-alpine" style={{ height: 140 }}>
                <AgGridReact
                    rowData={tasks}
                    columnDefs={[
                        { field: 'taskId', headerName: 'ID', checkboxSelection: true, width: 120 },
                        { field: 'assignedToName', headerName: 'Who', width: 120 },
                        { field: 'subject', headerName: 'Subject' },
                        { field: 'status', headerName: 'Status', width: 120 },
                        { field: 'priority', headerName: 'Pri', width: 80 },
                        { field: 'dueDate', headerName: 'Due', width: 80 },
                    ]}
                    rowHeight={25}
                    defaultColDef={{ sortable: true, filter: true, resizable: true }}
                    onRowClicked={(event) => {
                        const task = event.data as TaskDTO;
                        handleTaskSelect(task); // Notify parent component with selected task
                    }}
                />
            </div>

            {/* Post Task Action Form */}
            { (
                <PostTaskAction
                    selectedTask={selectedTask}
                    onPostSuccess={fetchContextTasks} // Refresh task actions after posting
                />
            )}

            {/* Task Form Modal */}
            {openForm && (
                <TaskForm
                    onClose={() => {
                        setOpenForm(false);
                        fetchContextTasks(); // Fetch tasks after form is closed
                    }}
                    contextType={contextType}
                    contextId={contextId}
                />
            )}
        </Box>
    );
};

export default ContextTasks;
