import { ThemeOptions } from '@mui/material';

export const winterTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#1565c0', // deep icy blue
        },
        secondary: {
            main: '#90caf9', // light sky blue
        },
        background: {
            default: '#e3f2fd', // very light blue
            paper: '#bbdefb',
        },
        text: {
            primary: '#0d47a1', // strong winter blue
            secondary: '#1976d2',
        },
    },
};
