import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { UserProvider } from "./context/UserContext";
import { CustomThemeProvider } from './themes';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <UserProvider>
        <CustomThemeProvider>
            <App />
        </CustomThemeProvider>
    </UserProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
