import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import {  InputAdornment, IconButton } from "@mui/material"; // ✅ Import Material-UI components
import { Clear } from "@mui/icons-material"; // ✅ Import "X" Clear Icon

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CircularProgress, Button, Box, Typography, TextField } from "@mui/material";
import apiConfig from "../apiConfig";
import { handleAgDocument } from "./handleDocuments";
import ApexPdfjsViewer from "./ApexPdfjsViewer";
import { useUser } from "../context/UserContext";
import ThreadPanel from "./chatthreads/ThreadPanel";

export interface PatientVisit {
    encid: number;
    visitType: string;
    encStatus: string;
    reason: string;
    DOS: number;
    apptAt: number;
    patientID: number;
    lastName: string;
    firstName: string;
    middleName: string;
    apex_facility: string;
    dob: number;
    upaddress: string;
    upaddress2: string;
    upcity: string;
    upstate: string;
    zipcode: string;
    refdoc: string;
    upPhone: string;
    pinsname: string;
    pinssubscriberNo: string;
}

export interface PatientDocument {
    patientid: number;
    Name: string;
    category: string;
    DOB: string;
    customname: string;
    created_at: number;
    dirpath: string;
    filename: string;
    filepath: string;
}

const API_URL = `${apiConfig.REACT_APEX_BASE_API}/apexai`;

const Schedule: React.FC = () => {
    const { accessToken } = useUser();
    const [visitData, setVisitData] = useState<PatientVisit[]>([]);
    const [docData, setDocData] = useState<PatientDocument[]>([]);
    const [loadingVisits, setLoadingVisits] = useState<boolean>(false);
    const [loadingDocs, setLoadingDocs] = useState<boolean>(false);
    const [selectedPatientID, setSelectedPatientID] = useState<number | null>(null);
    const [selectedPatientName, setSelectedPatientName] = useState<string | null>(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [currentPageNum, setCurrentPageNum] = useState<number>();

    // Quick Filter States
    const [visitFilter, setVisitFilter] = useState("");
    const [docFilter, setDocFilter] = useState("");

    const apiURL = apiConfig.REACT_APEX_BASE_API;

    const fetchScheduleData = useCallback(async () => {
        setLoadingVisits(true);

        if (!accessToken) {
            console.error("No valid token found. Please log in.");
            setLoadingVisits(false);
            return;
        }

        try {
            const response = await axios.get(`${API_URL}/schedule`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            setVisitData(response.data);
            console.error("API received data:", response.data);
        } catch (error) {
            console.error("API request failed:", error);
        } finally {
            setLoadingVisits(false);
        }
    }, [accessToken]);

    const fetchPatientDocuments = useCallback(async (patientID: number) => {
        setLoadingDocs(true);

        if (!accessToken) {
            console.error("No valid token found. Please log in.");
            setLoadingVisits(false);
            return;
        }

        try {
            const response = await axios.get(`${API_URL}/ptdocs?patientid=${patientID}`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            setDocData(response.data);
            console.error("API received data:", response.data);
        } catch (error) {
            console.error("API request failed:", error);
        } finally {
            setLoadingDocs(false);
        }
    }, [accessToken]);

    useEffect(() => {
        fetchScheduleData();
    }, [fetchScheduleData]);

    const handleVisitRowSelected = (event: { api: { getSelectedRows: () => any[] } }) => {
        const selectedNodes = event.api.getSelectedRows();
        if (selectedNodes.length > 0) {
            const selectedPatientID = selectedNodes[0].patientID;
            setSelectedPatientID(selectedPatientID);
            setSelectedPatientName(selectedNodes[0].firstName + ' ' + selectedNodes[0].lastName);
            fetchPatientDocuments(selectedPatientID);
        }
    };

    const handleDocumentRowSelected = (event: { api: { getSelectedRows: () => any[] } }) => {
        const selectedNodes = event.api.getSelectedRows();
        if (selectedNodes.length > 0) {
            ShowDocument(1, selectedNodes[0].dirpath + '/' + selectedNodes[0].filename);
        }
    };

    const ShowDocument = (pagenum: number, docpath: string) => {
        handleAgDocument({
            filename: docpath,
            dirpath: '',
            startpage: pagenum
        }, apiURL, accessToken, setPdfUrl, setCurrentPageNum);
    };

    const formatDate = (timestamp: number): string => {
        if (!timestamp) return "";
        const date = new Date(timestamp);
        return date.toISOString().split("T")[0]; // ✅ "YYYY-MM-DD"
    };

    const formatDateTime = (timestamp: number): string => {
        if (!timestamp) return "";
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        return `${date.toISOString().split("T")[0]} ${hours}:${minutes}`; // ✅ "YYYY-MM-DD HH:MM"
    };


    return (
        <Box display="flex" width="100%" height="100vh">
            {/* 🔹 LEFT SIDE: Visit Grid (Top) + Patient Docs Grid (Bottom) */}
            <Box width="50%" display="flex" flexDirection="column" gap={2} p={2}>
                {/* ✅ Visit Grid (Top - 40%) */}
                <Box flex={4} display="flex" flexDirection="column">
                    <Typography variant="h5">Schedule Data</Typography>
                    {/* ✅ Schedule Data & Refresh Button in the Same Line */}
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button variant="contained" onClick={fetchScheduleData} disabled={loadingVisits}>
                            {loadingVisits ? <CircularProgress size={20} /> : "Refresh Visits"}
                        </Button>

                        <TextField
                            label="Visit Quick Filter"
                            variant="outlined"
                            size="small"
                            sx={{ width: "250px" }}  // ✅ Fixed width
                            value={visitFilter}
                            onChange={(e) => setVisitFilter(e.target.value)}
                            InputProps={{
                                endAdornment: visitFilter && (
                                    <InputAdornment position="end">
                                        <IconButton size="small" onClick={() => setVisitFilter("")}>
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Box>



                    {loadingVisits ? (
                        <CircularProgress />
                    ) : (
                        <div className="ag-theme-alpine" style={{ flex: 1, width: "100%" }}>
                            <AgGridReact
                                rowData={visitData}
                                columnDefs={[
                                    { field: "encid", headerName: "Enc ID", width: 100, checkboxSelection: true },
                                    { field: "apptAt", headerName: "Appointment Time", width: 170 ,
                                        valueFormatter: params => formatDateTime(params.value),},
                                    {
                                        headerName: "Name",
                                        width: 200,
                                        valueGetter: params => {
                                            const firstName = params.data?.firstName || "";
                                            const middleInitial = params.data?.middleName ? `${params.data.middleName.charAt(0)}.` : "";
                                            const lastName = params.data?.lastName || "";
                                            return `${firstName} ${middleInitial} ${lastName}`.trim();
                                        }
                                    },

                                    { field: "visitType", headerName: "Visit Type", width: 100 },
                                    { field: "encStatus", headerName: "Enc Status", width: 100 },
                                    { field: "reason", headerName: "Reason", width: 200 },
                                    { field: "DOS", headerName: "Date of Service", width: 130 ,
                                        valueFormatter: params => formatDate(params.value),},
                                    { field: "dob", headerName: "Birth Date", width: 130 ,
                                        valueFormatter: params => formatDate(params.value),},


                                    { field: "patientID", headerName: "Patient ID", width: 110 },
                                ]}
                                rowSelection="single"
                                onSelectionChanged={handleVisitRowSelected}
                                animateRows
                                pagination
                                defaultColDef={{
                                    sortable: true,  // ✅ Enables sorting on all columns
                                    filter: true,    // ✅ Enables filtering on all columns
                                    resizable: true, // ✅ Enables resizing on all columns
                                }}
                                quickFilterText={visitFilter}
                            />
                        </div>
                    )}
                </Box>

                {/* ✅ Patient Docs Grid (Bottom - 40%) */}
                <Box flex={4} display="flex" flexDirection="column">
                    {/* ✅ Patient Docs Filter & Name Display */}
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <TextField
                            label="Docs Quick Filter"
                            variant="outlined"
                            size="small"
                            value={docFilter}
                            onChange={(e) => setDocFilter(e.target.value)}
                            InputProps={{
                                endAdornment: docFilter && (
                                    <InputAdornment position="end">
                                        <IconButton size="small" onClick={() => setDocFilter("")}>
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Typography variant="h6">
                            Patient Documents (ID: {selectedPatientID}) - {selectedPatientName}
                        </Typography>
                    </Box>
                    {loadingDocs ? (
                        <CircularProgress />
                    ) : (
                        <div className="ag-theme-alpine" style={{ flex: 1, width: "100%" }}>
                            <AgGridReact
                                rowData={docData}
                                columnDefs={[
                                    {
                                        field: "customname",
                                        headerName: "Custom Name",
                                        width: 300,
                                        checkboxSelection: true,
                                        //cellClass: "left-align-text" // ✅ Assigns a CSS class
                                    },
                                    {
                                        field: "ext",
                                        headerName: "Ext",
                                        width: 80,
                                        valueGetter: params => {
                                            if (!params.data?.filename) return "";
                                            return params.data.filename.split(".").pop()?.toUpperCase() || ""; // ✅ Extracts extension in UPPERCASE
                                        }
                                    },


                                    { field: "created_at", headerName: "Created At", width: 150,
                                        valueFormatter: params => formatDateTime(params.value),},
                                    {
                                        field: "category",
                                        headerName: "Category",
                                        width: 250,
                                        //cellClass: "left-align-text" // ✅ Assigns a CSS class
                                    },
                                    {
                                        field: "filepath",
                                        headerName: "File Path",
                                        width: 250,
                                        valueGetter: params => params.data ? `${params.data.dirpath}/${params.data.filename}` : "",
                                        tooltipValueGetter: params => params.data ? `${params.data.dirpath}/${params.data.filename}` : "" // Show full path on hover
                                    }

                                ]}
                                rowSelection="single"
                                onSelectionChanged={handleDocumentRowSelected}
                                animateRows
                                pagination
                                defaultColDef={{
                                    sortable: true,  // ✅ Enables sorting on all columns
                                    filter: true,    // ✅ Enables filtering on all columns
                                    resizable: true, // ✅ Enables resizing on all columns
                                }}
                                quickFilterText={docFilter}
                            />
                        </div>
                    )}
                </Box>
            </Box>


            {/* 🔹 RIGHT SIDE: PDF Viewer (100% Height) */}
            <Box width="50%" display="flex" flexDirection="column" p={2}>
                <ApexPdfjsViewer pdfUrl={pdfUrl} startingPageNum={currentPageNum} setCurrentPageNum={setCurrentPageNum} />
            </Box>
        </Box>
    );
};

export default Schedule;
