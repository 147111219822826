import { ThemeOptions } from '@mui/material';

export const fallTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#bf360c', // deep orange
        },
        secondary: {
            main: '#ff7043', // lighter orange
        },
        background: {
            default: '#fbe9e7', // peachy background
            paper: '#ffccbc',
        },
        text: {
            primary: '#3e2723', // dark brown
            secondary: '#6d4c41',
        },
    },
};
