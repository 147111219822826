import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid
} from '@mui/material';
import { TaskDTO } from './TaskBoard';
import { useUser } from "../../context/UserContext";
import axiosApexInstance from "../../auth/axiosApexInstance"; // ✅ custom instance

interface TaskFormProps {
    onClose: () => void;
    contextType: string ; //'fax' | 'voicemail' | '';  // Add contextType prop
    contextId: number;  // Add contextId prop
}

const TaskForm: React.FC<TaskFormProps> = ({ onClose, contextType, contextId }) => {
    const [form, setForm] = useState<Partial<TaskDTO>>({
        status: 'new',
        priority: 1,
        contextType, // Set contextType in the form state
        contextId,  // Set contextId in the form state
    });
    const { apexUser } = useUser();

    const handleChange = (field: keyof TaskDTO, value: any) => {
        setForm(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = async () => {
        try {
            await axiosApexInstance.post('/task', form);
            onClose();
        } catch (error) {
            console.error("Failed to create task:", error);
        }
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Create Task</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Subject" fullWidth required value={form.subject || ''} onChange={e => handleChange('subject', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Task Key" fullWidth value={form.taskKey || ''} onChange={e => handleChange('taskKey', e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Description" fullWidth multiline rows={3} value={form.description || ''} onChange={e => handleChange('description', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Assigned To ID" type="number" fullWidth value={form.assignedToId || ''} onChange={e => handleChange('assignedToId', parseInt(e.target.value))} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Assigned To Name" fullWidth value={form.assignedToName || ''} onChange={e => handleChange('assignedToName', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Context Type" fullWidth value={form.contextType || ''} onChange={e => handleChange('contextType', e.target.value)} disabled />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Context ID" type="number" fullWidth value={form.contextId || ''} onChange={e => handleChange('contextId', parseInt(e.target.value))} disabled />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Priority" type="number" fullWidth value={form.priority || ''} onChange={e => handleChange('priority', parseInt(e.target.value))} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Status" fullWidth value={form.status || ''} onChange={e => handleChange('status', e.target.value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained">Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TaskForm;
