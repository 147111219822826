// components/ThemeSwitcher.tsx
import React, { useState } from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Snackbar,
    Alert,
    SelectChangeEvent,
} from '@mui/material';
import { useThemeSwitcher, ThemeName } from '../themes';

export const ThemeSwitcher: React.FC = () => {
    const { themeName, setThemeName } = useThemeSwitcher();
    const [open, setOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');

    const handleChange = (event: SelectChangeEvent<ThemeName>) => {
        const newTheme = event.target.value as ThemeName;
        setThemeName(newTheme);

        // Set and show snackbar
        setSnackMsg(`Switched to ${newTheme.charAt(0).toUpperCase() + newTheme.slice(1)} Theme`);
        setOpen(true);
    };

    return (
        <>
            <FormControl size="small" sx={{ minWidth: 140 }}>
                <InputLabel id="theme-switcher-label">Theme</InputLabel>
                <Select
                    labelId="theme-switcher-label"
                    id="theme-switcher"
                    value={themeName}
                    label="Theme"
                    onChange={handleChange}
                >
                    <MenuItem value="spring">🌸 Spring</MenuItem>
                    <MenuItem value="summer">☀️ Summer</MenuItem>
                    <MenuItem value="fall">🍂 Fall</MenuItem>
                    <MenuItem value="winter">❄️ Winter</MenuItem>
                </Select>
            </FormControl>

            <Snackbar
                open={open}
                autoHideDuration={2500}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
        </>
    );
};
